import { useStaticQuery, graphql } from "gatsby"

export const useStandings = () => {
  const { allStandingsJson } = useStaticQuery(graphql`
    query {
      allStandingsJson {
        nodes {
          aagainst
          afor
          adraw
          against
          alose
          aplayed
          awin
          draw
          for
          form
          fullName
          slug
          goalsDiff
          hagainst
          hdraw
          hfor
          hlose
          hplayed
          hwin
          lose
          played
          rank
          shortName
          win,
          points
          shortName
        }
      }
    }
  `);

  return allStandingsJson.nodes.map((n) => {
    return {
      fullName: n.fullName,
      slug: n.slug,
      played: n.played,
      win: n.win,
      draw: n.draw,
      lose: n.lose,
      goalsDiff: n.goalsDiff,
      rank: n.rank,
      points: n.points,
      form: n.form,
      for: n.for,
      against: n.against,
      shortName: n.shortName
    };
  });;
}
