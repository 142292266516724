import { useStaticQuery, graphql } from "gatsby";

export const useAllMatches = () => {
  const { allMatchesJson } = useStaticQuery(graphql`
    query {
      allMatchesJson {
        nodes {
          away {
            fullName
            shortName
            slug
          }
          city
          date
          ftScore {
            away
            home
          }
          home {
            fullName
            shortName
            slug
          }
          htScore {
            away
            home
          }
          statistics {
            away {
              xg
              ballPossession
              blockedShots
              cornerKicks
              fouls
              goalkeeperSaves
              offsides
              passesAccurate
              passAccuracy
              redCards
              shotsInsideBox
              shotsOffGoal
              shotsOnGoal
              shotsOutsideBox
              totalPasses
              totalShots
              yellowCards
            }
            home {
              xg
              ballPossession
              blockedShots
              cornerKicks
              fouls
              goalkeeperSaves
              offsides
              passAccuracy
              passesAccurate
              redCards
              shotsInsideBox
              shotsOnGoal
              shotsOffGoal
              shotsOutsideBox
              totalPasses
              totalShots
              yellowCards
            }
          }
          newDate
          note
          round
          status
          venueName
        }
      }
    }
  `);

  const allMatches = allMatchesJson.nodes
    .map((n) => {
      return {
        id: n.id,
        home: n.home,
        homeFullName: n.home.fullName,
        homeShortName: n.home.shortName,
        homeSlug: n.home.slug,
        homeScore: n.ftScore.home,
        away: n.away,
        awayFullName: n.away.fullName,
        awayShortName: n.away.shortName,
        awaySlug: n.away.slug,
        awayScore: n.ftScore.away,
        date: n.newDate !== null ? new Date(n.newDate) : new Date(n.date),
        oldDate: n.newDate !== null ? new Date(n.date) : null,
        round: n.round,
        venueName: n.venueName,
        city: n.city,
        ftScore: n.ftScore,
        note: n.note,
        statistics: n.statistics,
        status: n.status,
      };
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return {
    allMatches,
    areAllMatchesPlayed: allMatches[allMatches.length - 1].status === "FT",
  };
};
