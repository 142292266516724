import React from "react";

import {
  teamStatisticsNameMap,
  matchStatisticsNameMap,
} from "../util/statisticsNameMap";

const teamCount = 19;

const StatisticsBar = ({
  homeStatistic,
  awayStatistic,
  homeRank,
  awayRank,
  statisticName,
  statisticsType,
  homeColor,
  awayColor,
}) => {
  let homeWidthRate, awayWidthRate, homeRate, awayRate;
  const statistic =
    statisticsType === "team statistics"
      ? teamStatisticsNameMap[statisticName]
      : matchStatisticsNameMap[statisticName];

  if (homeStatistic === 0 && awayStatistic === 0) {
    homeWidthRate = "per-0";
    awayWidthRate = "per-0";
  } else {
    homeRate = Math.round(
      (homeStatistic * 100) / (awayStatistic + homeStatistic)
    );
    awayRate = 100 - homeRate;
    homeWidthRate = `per-${homeRate}`;
    awayWidthRate = `per-${awayRate}`;
  }
  const homeStyle = `h-full ${homeWidthRate} ${homeColor}`;
  const awayStyle = `h-full ${awayWidthRate} ${awayColor}`;
  return (
    <div className="flex flex-col items-center">
      <div className="w-full flex text-xs font-bold my-3 sm:text-md">
        <div className="w-1/3 flex justfiy-start gap-2">
          {homeStatistic}
          {statistic.textToAppend}
          {homeRank && (
            <span>
              ({homeRank}/{teamCount})
            </span>
          )}
        </div>
        <div className="w-full flex justify-center">{statistic.label}</div>
        <div className="w-1/3 flex justify-end">
          {homeRank && (
            <span>
              ({awayRank}/{teamCount}) &nbsp;
            </span>
          )}
          {awayStatistic}
          {statistic.textToAppend}
        </div>
      </div>
      <div className="w-full flex gap-1">
        <div className="w-1/2 h-3 bg-gray-100 flex justify-end">
          <div className={homeStyle}></div>
        </div>
        <div className="w-1/2 h-3 bg-gray-100">
          <div className={awayStyle}></div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsBar;
