import React from "react";

import MatchInfo from "./MatchInfo";
import StatisticsBar from "../components/StatisticsBar";

const StatisticsPanel = ({ matchesJson }) => {
  const homeColor = `bg-${matchesJson.home.slug}-1`;
  const awayColor = `bg-${matchesJson.away.slug}-1`;
  const orderedStatsArray = [
    "xg",
    "ballPossession",
    "totalShots",
    "shotsOnGoal",
    "goalkeeperSaves",
    "shotsOffGoal",
    "blockedShots",
    "shotsInsideBox",
    "shotsOutsideBox",
    "cornerKicks",
    "totalPasses",
    "passesAccurate",
    "passAccuracy",
    "offsides",
    "fouls",
    "yellowCards",
    "redCards",
  ];

  return (
    <div className="w-10/12">
      {orderedStatsArray.map((item, ind) => {
        return (
          <StatisticsBar
            key={ind}
            homeStatistic={matchesJson.statistics.home[item]}
            awayStatistic={matchesJson.statistics.away[item]}
            statisticName={item}
            homeColor={homeColor}
            awayColor={awayColor}
          />
        );
      })}
    </div>
  );
};

const InformationText = ({ matchesJson }) => {
  return (
    <>
      <div className="px-0.5 py-0.5 lg:hidden">
        {matchesJson.note
          ? `${matchesJson.note}`
          : `${matchesJson.home.shortName} - ${matchesJson.away.shortName}
        maçı için henüz maç istatistikleri elde edilmemiştir.`}
      </div>
      <div className="hidden lg:flex px-0.5">
        {matchesJson.note
          ? `${matchesJson.note}`
          : `${matchesJson.home.fullName} - ${matchesJson.away.fullName}
          maçı için henüz maç istatistikleri elde edilmemiştir.`}
      </div>
    </>
  );
};

const FinishedMatchStatistics = ({ matchesJson }) => {
  const statisticsExisting =
    Boolean(matchesJson.statistics) && matchesJson.status === "FINISHED";
  return (
    <div className="flex flex-col w-full pb-8 dark:text-white">
      <>
        <div className="flex justify-center mt-4 sm:mb-4">
          <MatchInfo matchesJson={matchesJson}>
            {statisticsExisting ? (
              <>
                <div className="flex justify-center items-center font-bold text-md lg:text-6xl mt-2">
                  {matchesJson.ftScore.home}-{matchesJson.ftScore.away}
                </div>

                <div className="flex justify-center items-center text-xs lg:text-md mt-2">
                  Yarı: {matchesJson.htScore.home}-{matchesJson.htScore.away}
                </div>
              </>
            ) : (
              <div className="flex h-full w-full text-center items-center sm:font-bold text-xs lg:text-sm">
                <InformationText matchesJson={matchesJson} />
              </div>
            )}
          </MatchInfo>
        </div>

        {statisticsExisting && (
          <div className="flex flex-col items-center justify-center mt-4">
            <h1 className="font-bold text-center w-full">Maç İstatisikleri</h1>
            <StatisticsPanel matchesJson={matchesJson} />
          </div>
        )}
      </>
    </div>
  );
};

export default FinishedMatchStatistics;
