import React from "react";

function stopEventPropagation(e) {
  e.stopPropagation();
}

const ExternalLink = ({
  Icon,
  path,
  textClass = "text-white",
  marginClass = "mr-2",
  widthHeightClass = "w-6 h-6",
}) => (
  <object>
    <a
      href={path}
      target="_blank"
      rel="noreferrer"
      onClick={stopEventPropagation}
    >
      <Icon className={`${textClass} ${marginClass} ${widthHeightClass}`} />
    </a>
  </object>
);

export default ExternalLink;
