import React, { useCallback, useEffect, useState } from "react";

import StatisticsBar from "../components/StatisticsBar";
import { useAllMatches } from "../hooks/useAllMatches";
import { useStandings } from "../hooks/useStandings";
import { useTeamsStats } from "../hooks/useTeamsStats";
import { getTime } from "../util/dateUtil";
import { findFiveClosestStandings } from "../util/standingsFixturesUtil";
import Combobox from "./Combobox";
import MatchInfo from "./MatchInfo";
import SimpleStandings from "./SimpleStandings";

const addZeros = (decimal, value) => {
  if (decimal <= value.length) return value;
  if (decimal <= 0) return value;
  const newValue = value.length <= decimal ? "0" + value : value;
  return addZeros(decimal - 1, newValue, false);
};

const getMatchSummary = (fullName, allMatches, standings) => {
  const matches = allMatches.filter(
    (node) => node.home.fullName === fullName || node.away.fullName === fullName
  );

  const fiveClosestStandings = findFiveClosestStandings(standings, fullName);

  const passedMatches = matches.filter((node) => node.homeScore !== null);
  let fivePassedMatches;
  if (passedMatches.length < 5) fivePassedMatches = passedMatches;
  else {
    fivePassedMatches = passedMatches.slice(
      passedMatches.length - 5,
      passedMatches.length
    );
  }

  return {
    fiveClosestStandings,
    fivePassedMatches,
  };
};

const Timer = ({ matchDate }) => {
  const [date, setDate] = useState({});
  const refreshClock = useCallback(() => {
    let diffTime = Math.abs(
      new Date().valueOf() - new Date(matchDate).valueOf()
    );
    let days = diffTime / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;
    setDate({
      days: addZeros(2, Math.floor(days).toString()),
      hours: addZeros(2, Math.floor(hours).toString()),
      minutes: addZeros(2, Math.floor(minutes).toString()),
      secs: addZeros(2, Math.floor(secs).toString()),
    });
  }, [matchDate]);

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [refreshClock]);
  return getTime(new Date(matchDate)) !== "03:00" ? (
    <div className="flex w-full justify-center">
      <div className="shrink-0 w-20 sm:w-24 md:w-28 py-2 lg:w-40 xl:w-48 h-12 lg:h-16 rounded-lg border border-solid bg-fuchsia-900 text-white flex justify-center items-center font-bold">
        <div className="flex justify-center gap-1 lg:gap-2 text-xs md:text-sm lg:text-base xl:text-xl">
          <div className="flex flex-col items-center">
            {date.days}
            <span className="lg:hidden">g</span>
            <span className="hidden lg:block">gün</span>
          </div>
          <div className="flex flex-col items-center">
            {date.hours}
            <span className="lg:hidden">s</span>
            <span className="hidden lg:block">sa</span>
          </div>
          <div className="flex flex-col items-center">
            {date.minutes}
            <span className="lg:hidden">d</span>
            <span className="hidden lg:block">dk</span>
          </div>
          <div className="flex flex-col items-center">
            {date.secs}
            <span className="lg:hidden">s</span>
            <span className="hidden lg:block">sn</span>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const TeamSummaries = ({ fullName, matchesSummary, className }) => {
  return (
    <div className="flex flex-col my-8 w-full">
      <div className={`flex ${className} w-full`}>
        <div className="hidden sm:flex sm:flex-col sm:h-full sm:my-4 sm:w-11/12 max-w-xs sm:visible lg:w-full">
          <div className="justify-center mb-4 flex font-bold">{fullName}</div>
          <SimpleStandings
            standings={matchesSummary.fiveClosestStandings}
            teamToHighlight={fullName}
          />
        </div>
      </div>
    </div>
  );
};

const updateHomeAndAwayStats = (
  allTeamsStats,
  statisticsType,
  homeSlug,
  awaySlug
) => {
  const allHomeStats =
    allTeamsStats.find((team) => team.slug === homeSlug).statistics || {};
  const homeStats =
    statisticsType === "Ev-Deplasman İstatisikleri"
      ? { ...allHomeStats.home }
      : { ...allHomeStats.total };

  const allAwayStats =
    allTeamsStats.find((team) => team.slug === awaySlug).statistics || {};
  const awayStats =
    statisticsType === "Ev-Deplasman İstatisikleri"
      ? { ...allAwayStats.away }
      : { ...allAwayStats.total };
  return { homeStats, awayStats };
};

const UpcomingMatchesStatistics = ({ matchesJson }) => {
  const statisticsToBePresented = [
    "avgXg",
    "avgFtFor",
    "avgFtAgainst",
    "avgTotalShots",
    "avgShotsOnGoal",
    "avgCornerKicks",
    "avgGoalkeeperSaves",
    "avgBallPossession",
    "avgPassAccuracy",
    "avgPassesAccurate",
    "avgTotalPasses",
    "avgYellowCards",
    "redCards",
    "avgFouls",
    "avgHtFor",
    "avgHtAgainst",
    "avgOffsides",
    "avgShotsInsideBox",
    "avgShotsOutsideBox",
    "avgShotsOffGoal",
    "avgBlockedShots",
    "yellowCards",
  ];
  const { allMatches } = useAllMatches();
  const allStandings = useStandings();
  const allTeamsStats = useTeamsStats();
  const data = matchesJson;
  const date = data.newDate === null ? data.date : data.newDate;
  const homeColor = `bg-${matchesJson.home.slug}-1`;
  const awayColor = `bg-${matchesJson.away.slug}-1`;

  const statisticsTypes = [
    "Ev-Deplasman İstatisikleri",
    "Toplam İstatistikler",
  ];
  const [statisticsType, setStatisticsType] = useState(statisticsTypes[0]);

  const selectStatisticsType = (val) => {
    setStatisticsType(val);
  };

  const homeMatchesSummary = getMatchSummary(
    data.home.fullName,
    allMatches,
    allStandings
  );
  const awayMatchesSummary = getMatchSummary(
    data.away.fullName,
    allMatches,
    allStandings
  );

  const { homeStats, awayStats } = updateHomeAndAwayStats(
    allTeamsStats,
    statisticsType,
    data.home.slug,
    data.away.slug
  );
  return (
    <div className="flex flex-col w-full dark:bg-gray-900 dark:text-white">
      <div className="flex justify-center mt-4 sm:mb-4">
        <MatchInfo
          matchesJson={data}
          homeMatchesSummary={homeMatchesSummary}
          awayMatchesSummary={awayMatchesSummary}
        >
          {getTime(new Date(date)) === "03:00" ? (
            <span className="flex w-full h-full text-center justify-center items-center">
              Saati belli değil
            </span>
          ) : (
            <div className="lg:flex lg:justify-center lg:items-center lg:w-48 mt-6">
              <Timer matchDate={date} />
            </div>
          )}
        </MatchInfo>
      </div>
      <div className="flex-col items-center">
        {Object.keys(homeStats).length > 0 &&
          Object.keys(awayStats).length > 0 && (
            <>
              <div className="flex flex-col text-center items-center w-full my-8">
                <h1 className="font-bold w-full flex justify-center">
                  Maç Öncesi İstatisikleri
                </h1>
                <div className="flex justify-center w-full">
                  <Combobox
                    data={statisticsTypes}
                    selected={statisticsType}
                    onSelect={selectStatisticsType}
                    width="w-64"
                  />
                </div>
                <div className="flex text-xs mt-8 font-bold text-sm w-full">
                  <div className="w-1/2 flex justify-end pr-4">
                    {data.home.fullName}
                    {statisticsType === "Ev-Deplasman İstatisikleri"
                      ? " Ev "
                      : " Toplam Maç "}
                    İstatisikleri
                  </div>
                  <div className="w-1/2 flex justify-start pl-4">
                    {data.away.fullName}
                    {statisticsType === "Ev-Deplasman İstatisikleri"
                      ? " Deplasman "
                      : " Toplam Maç "}
                    İstatisikleri
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="w-10/12">
                  {statisticsToBePresented.map((item, ind) => {
                    if (item !== "totalMatches") {
                      return (
                        <StatisticsBar
                          key={ind}
                          homeStatistic={homeStats[item].value}
                          awayStatistic={awayStats[item].value}
                          homeRank={homeStats[item].rank}
                          awayRank={awayStats[item].rank}
                          statisticName={item}
                          statisticsType="team statistics"
                          homeColor={homeColor}
                          awayColor={awayColor}
                        />
                      );
                    } else return null;
                  })}
                </div>
              </div>
            </>
          )}
        <div className="flex justify-center lg:mx-0 w-full">
          <div className="flex justify-center lg:mx-0 w-1/2">
            <TeamSummaries
              fullName={data.home.fullName}
              matchesSummary={homeMatchesSummary}
              className="justify-end pr-4"
            />
          </div>
          <div className="flex justify-center lg:mx-0 w-1/2">
            <TeamSummaries
              fullName={data.away.fullName}
              matchesSummary={awayMatchesSummary}
              className="justify-start pl-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingMatchesStatistics;
