export const findFiveMatches = (matches, timePassed, teamName) => {
  const today = new Date();
  let fiveMatches = [];
  let allMatches = !timePassed
    ? matches.filter((node) => new Date(node.date) >= today)
    : matches.filter((node) => new Date(node.date) < today);

  allMatches.forEach((match) => {
    if (fiveMatches.length < 5) {
      if (
        match.home.fullName === teamName ||
        match.away.fullName === teamName
      ) {
        fiveMatches.push(match);
      }
    }
  });
  return fiveMatches;
};

export const findFiveClosestStandings = (standings, teamFullName) => {
  let fiveClosestStandings;

  const currentTeamsRank = standings.findIndex(
    (item) => item.fullName === teamFullName
  );

  if (currentTeamsRank < 3) {
    fiveClosestStandings = standings.slice(0, 5);
  } else if (currentTeamsRank < standings.length - 2) {
    fiveClosestStandings = standings.slice(
      currentTeamsRank - 2,
      currentTeamsRank + 3
    );
  } else {
    fiveClosestStandings = standings.slice(
      standings.length - 5,
      standings.length
    );
  }
  return fiveClosestStandings;
};
