const defaultLocale = "tr-TR";

const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const timeOptions = { hour: "2-digit", minute: "2-digit" };

export function getDate(date) {
  return date.toLocaleDateString(defaultLocale, dateOptions);
}

export function getTime(date) {
  return date.toLocaleTimeString(defaultLocale, timeOptions);
}
export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
