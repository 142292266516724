import React from "react";
import { Link } from "gatsby";
import { FaArrowRight } from "react-icons/fa";

import { getTeamURL } from "../util/urlUtil";
import { logos } from "../util/logos";

const Th = ({ children, appendedClasses = "" }) => (
  <th
    className={`text-center font-light text-xs border-solid border-x-0 border-slate-200 dark:text-white dark:bg-gray-900 ${appendedClasses}`}
  >
    {children}
  </th>
);
const Td = ({ children, appendedClasses = "" }) => (
  <td
    className={`text-xs border border-solid border-x-0 border-slate-200 font-medium ${appendedClasses}`}
  >
    {children}
  </td>
);

const CellContent = ({
  rowContent,
  children,
  teamToHighlight,
  classes = "",
}) => (
  <span
    className={`${
      teamToHighlight === rowContent.fullName ? "font-bold" : "font-md"
    } ${classes}`}
  >
    {children}
  </span>
);

function SimpleStandings({ standings, teamToHighlight }) {
  return (
    <div className="flex flex-col w-full border-2 border-solid border-slate-200 shadow-md w-full dark:text-white">
      <table className="border-collapse border border-solid border-x-0 border-slate-200 w-full">
        <thead>
          <tr className="bg-gray-50">
            <Th appendedClasses="w-6">&nbsp;</Th>
            <Th>&nbsp;</Th>
            <Th>O</Th>
            <Th>Av</Th>
            <Th appendedClasses="pr-2">P</Th>
          </tr>
        </thead>
        <tbody>
          {standings.map((s, i) => (
            <tr className="h-10" key={s.fullName}>
              <Td appendedClasses="w-6 text-center font-semibold">
                <CellContent teamToHighlight={teamToHighlight} rowContent={s}>
                  {s.rank}
                </CellContent>
              </Td>
              <Td appendedClasses="text-left font-semibold">
                <Link className="flex items-center" to={getTeamURL(s.fullName)}>
                  <img
                    width="20px"
                    height="20px"
                    src={logos[s.slug]}
                    alt={s.fullName}
                  />
                  <CellContent
                    teamToHighlight={teamToHighlight}
                    rowContent={s}
                    classes="ml-2"
                  >
                    {s.fullName}
                  </CellContent>
                </Link>
              </Td>
              <Td appendedClasses="text-center">
                <CellContent
                  teamToHighlight={teamToHighlight}
                  rowContent={s}
                  classes="ml-2"
                >
                  {s.played}
                </CellContent>
              </Td>
              <Td appendedClasses="text-center">
                <CellContent
                  teamToHighlight={teamToHighlight}
                  rowContent={s}
                  classes="ml-2"
                >
                  {s.goalsDiff > 0 ? `+${s.goalsDiff}` : s.goalsDiff}
                </CellContent>
              </Td>
              <Td appendedClasses="text-center font-semibold pr-2">
                <CellContent
                  teamToHighlight={teamToHighlight}
                  rowContent={s}
                  classes="ml-2"
                >
                  {s.points}
                </CellContent>
              </Td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex h-12 w-full justify-center items-center">
        <Link to="/puan-durumu/" className="font-bold text-xs">
          Tüm Puan Durumunu Görüntüle
        </Link>
        <FaArrowRight className="ml-2 text-pink-600" />
      </div>
    </div>
  );
}

export default SimpleStandings;
