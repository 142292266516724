const teamMap = {
  Alanyaspor: "corendon-alanyaspor",
  Hatayspor: "atakas-hatayspor",
  Beşiktaş: "besiktas",
  Fenerbahçe: "fenerbahce",
  Galatasaray: "galatasaray",
  "Gaziantep FK": "gaziantep-fk",
  Konyaspor: "ittifak-holding-konyaspor",
  Rizespor: "caykur-rizespor",
  Trabzonspor: "trabzonspor",
  Samsunspor: "samsunspor",
  Kayserispor: "yukatel-kayserispor",
  Antalyaspor: "fraport-tav-antalyaspor",
  Sivasspor: "sivasspor",
  Başakşehir: "istanbul-basaksehir",
  Bodrumspor: "bodrumspor",
  "Adana Demirspor": "adana-demirspor",
  Göztepe: "goztepe",
  Eyüpspor: "eyupspor",
  Kasımpaşa: "kasimpasa",
};

const URL_START =
  "https://beinsports.com.tr/mac-ozetleri-goller/super-lig/ozet/2024-2025/";
const URL_END = "-mac-ozeti";

export function getMatchLink(match) {
  const { home, away } = getScoreForLink(match);
  return (
    URL_START +
    "/" +
    match.round +
    "/" +
    teamMap[match.home.fullName] +
    "-" +
    home +
    "-" +
    away +
    "-" +
    teamMap[match.away.fullName] +
    URL_END
  );
}

function getScoreForLink(match) {
  if (
    match.home.fullName === "İstanbulspor" &&
    match.away.fullName === "Trabzonspor"
  ) {
    return { home: 1, away: 2 };
  }

  return { home: match.ftScore.home, away: match.ftScore.away };
}
