import React, { useState } from "react";
import { Link } from "gatsby";

import { getMatchURL } from "../util/urlUtil";
import { logos } from "../util/logos";

const MatchInfoPopup = ({ match }) => {
  const homeLogo = (
    <img width="30px" height="30px" src={logos[match.homeSlug]} alt={match.homeFullName}/>
  );

  const awayLogo = (
    <img width="30px" height="30px" src={logos[match.awaySlug]} alt={match.awayFullName}/>
  );

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const dateString = new Date(match.date).toLocaleDateString("tr-TR", options);

  return (
    <Link to={getMatchURL(match.homeFullName, match.awayFullName)}>
      <div className="bg-white z-10 absolute -top-14 shadow-md hover:bg-gradient-to-r hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500">
        <div className="h-12 w-56 flex justify-center items-center gap-2 my-2">
          <div className="flex flex-col">
            <div className="flex justify-center text-black my-1 text-xs">
              {dateString}
            </div>
            <div className="flex text-xs text-black items-center">
              <span className="mr-2">{match.homeShortName}</span> {homeLogo}
              <span className="bg-indigo-600 ml-2 font-semibold text-white w-5 h-5 flex justify-center items-center mr-0.5">
                {match.homeScore}
              </span>
              <span className="bg-indigo-600 mr-2 font-semibold text-white w-5 h-5 flex justify-center items-center">
                {match.awayScore}
              </span>
              <span className="mr-2">{awayLogo} </span> {match.awayShortName}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Form = ({ fullName, pastMatches }) => {
  let formLetter;
  let showInfoJson = {};
  let pastMatchesForm = "";
  pastMatches.forEach((match) => {
    if (match.homeFullName === fullName) {
      if (match.awayScore < match.homeScore) {
        pastMatchesForm += "W";
      } else if (match.awayScore > match.homeScore) {
        pastMatchesForm += "L";
      } else if (match.awayScore === match.homeScore) {
        pastMatchesForm += "D";
      }
    } else if (match.awayFullName === fullName) {
      if (match.awayScore > match.homeScore) {
        pastMatchesForm += "W";
      } else if (match.awayScore < match.homeScore) {
        pastMatchesForm += "L";
      } else if (match.awayScore === match.homeScore) {
        pastMatchesForm += "D";
      }
    }
  });
  const formAr = pastMatchesForm.split("");
  formAr.forEach((item, ind) => {
    showInfoJson[ind] = false;
  });
  const [showInfo, setShowInfo] = useState({ showInfoJson });
  return (
    <div className="flex">
      {pastMatchesForm.split("").map((s, ind) => {
        let bgColor;
        if (s === "W") {
          bgColor = "bg-green-500";
          formLetter = "G";
        } else if (s === "D") {
          bgColor = "bg-yellow-500";
          formLetter = "B";
        } else if (s === "L") {
          bgColor = "bg-red-500";
          formLetter = "M";
        }
        return (
          <div
            role="presentation"
            key={ind}
            className={`${bgColor} relative mr-1 sm:font-bold sm:mx-1 w-4 h-4 sm:w-5 sm:h-5 text-xs sm:text-xs md:w-6 md:h-6 text-white font-bold flex items-center justify-center rounded-full`}
            onMouseEnter={() => {
              const updatedInfo = { ...showInfo };
              updatedInfo[ind] = true;
              setShowInfo(updatedInfo);
            }}
            onMouseLeave={() => {
              const updatedInfo = { ...showInfo };
              updatedInfo[ind] = false;
              setShowInfo(updatedInfo);
            }}
          >
            {showInfo[ind] && (
              <MatchInfoPopup match={pastMatches[ind]} />
            )}
            {formLetter}
          </div>
        );
      })}
    </div>
  );
};

export default Form;
