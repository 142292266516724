import { useStaticQuery, graphql } from "gatsby";

export const useTeamsStats = () => {
  const { allTeamsJson } = useStaticQuery(graphql`
    query {
      allTeamsJson {
        nodes {
          slug
          statistics {
            away {
              avgCornerKicks {
                rank
                value
              }
              avgBallPossession {
                rank
                value
              }
              avgBlockedShots {
                rank
                value
              }
              avgFouls {
                rank
                value
              }
              avgFtAgainst {
                rank
                value
              }
              avgFtFor {
                value
                rank
              }
              avgGoalkeeperSaves {
                rank
                value
              }
              avgHtAgainst {
                rank
                value
              }
              avgHtFor {
                rank
                value
              }
              avgOffsides {
                rank
                value
              }
              avgPassAccuracy {
                rank
                value
              }
              avgPassesAccurate {
                rank
                value
              }
              avgRedCards {
                rank
                value
              }
              avgShotsInsideBox {
                rank
                value
              }
              avgShotsOffGoal {
                rank
                value
              }
              avgShotsOutsideBox {
                rank
                value
              }
              avgShotsOnGoal {
                rank
                value
              }
              avgTotalMatches {
                value
                rank
              }
              avgTotalPasses {
                rank
                value
              }
              avgTotalShots {
                rank
                value
              }
              avgXg {
                rank
                value
              }
              avgYellowCards {
                rank
                value
              }
              ballPossession {
                rank
                value
              }
              blockedShots {
                value
                rank
              }
              cornerKicks {
                rank
                value
              }
              fouls {
                rank
                value
              }
              ftAgainst {
                value
                rank
              }
              ftFor {
                rank
                value
              }
              goalkeeperSaves {
                rank
                value
              }
              htAgainst {
                rank
                value
              }
              htFor {
                rank
                value
              }
              passAccuracy {
                rank
                value
              }
              offsides {
                rank
                value
              }
              passesAccurate {
                rank
                value
              }
              redCards {
                rank
                value
              }
              shotsInsideBox {
                rank
                value
              }
              shotsOnGoal {
                value
                rank
              }
              shotsOffGoal {
                rank
                value
              }
              shotsOutsideBox {
                rank
                value
              }
              totalMatches {
                rank
                value
              }
              totalPasses {
                rank
                value
              }
              totalShots {
                rank
                value
              }
              yellowCards {
                rank
                value
              }
              xg {
                rank
                value
              } 
            }
            home {
              avgCornerKicks {
                rank
                value
              }
              avgBallPossession {
                rank
                value
              }
              avgBlockedShots {
                rank
                value
              }
              avgFouls {
                rank
                value
              }
              avgFtAgainst {
                rank
                value
              }
              avgFtFor {
                value
                rank
              }
              avgGoalkeeperSaves {
                rank
                value
              }
              avgHtAgainst {
                rank
                value
              }
              avgHtFor {
                rank
                value
              }
              avgOffsides {
                rank
                value
              }
              avgPassAccuracy {
                rank
                value
              }
              avgPassesAccurate {
                rank
                value
              }
              avgRedCards {
                rank
                value
              }
              avgShotsInsideBox {
                rank
                value
              }
              avgShotsOffGoal {
                rank
                value
              }
              avgShotsOutsideBox {
                rank
                value
              }
              avgShotsOnGoal {
                rank
                value
              }
              avgTotalMatches {
                value
                rank
              }
              avgTotalPasses {
                rank
                value
              }
              avgTotalShots {
                rank
                value
              }
              avgXg {
                rank
                value
              }
              avgYellowCards {
                rank
                value
              }
              ballPossession {
                rank
                value
              }
              blockedShots {
                value
                rank
              }
              cornerKicks {
                rank
                value
              }
              fouls {
                rank
                value
              }
              ftAgainst {
                value
                rank
              }
              ftFor {
                rank
                value
              }
              goalkeeperSaves {
                rank
                value
              }
              htAgainst {
                rank
                value
              }
              htFor {
                rank
                value
              }
              passAccuracy {
                rank
                value
              }
              offsides {
                rank
                value
              }
              passesAccurate {
                rank
                value
              }
              redCards {
                rank
                value
              }
              shotsInsideBox {
                rank
                value
              }
              shotsOnGoal {
                value
                rank
              }
              shotsOffGoal {
                rank
                value
              }
              shotsOutsideBox {
                rank
                value
              }
              totalMatches {
                rank
                value
              }
              totalPasses {
                rank
                value
              }
              totalShots {
                rank
                value
              }
              yellowCards {
                rank
                value
              }
              xg {
                rank
                value
              }
            }
            total {
              avgCornerKicks {
                rank
                value
              }
              avgBallPossession {
                rank
                value
              }
              avgBlockedShots {
                rank
                value
              }
              avgFouls {
                rank
                value
              }
              avgFtAgainst {
                rank
                value
              }
              avgFtFor {
                value
                rank
              }
              avgGoalkeeperSaves {
                rank
                value
              }
              avgHtAgainst {
                rank
                value
              }
              avgHtFor {
                rank
                value
              }
              avgOffsides {
                rank
                value
              }
              avgPassAccuracy {
                rank
                value
              }
              avgPassesAccurate {
                rank
                value
              }
              avgRedCards {
                rank
                value
              }
              avgShotsInsideBox {
                rank
                value
              }
              avgShotsOffGoal {
                rank
                value
              }
              avgShotsOutsideBox {
                rank
                value
              }
              avgShotsOnGoal {
                rank
                value
              }
              avgTotalMatches {
                value
                rank
              }
              avgTotalPasses {
                rank
                value
              }
              avgTotalShots {
                rank
                value
              }
              avgXg {
                rank
                value
              }
              avgYellowCards {
                rank
                value
              }
              ballPossession {
                rank
                value
              }
              blockedShots {
                value
                rank
              }
              cornerKicks {
                rank
                value
              }
              fouls {
                rank
                value
              }
              ftAgainst {
                value
                rank
              }
              ftFor {
                rank
                value
              }
              goalkeeperSaves {
                rank
                value
              }
              htAgainst {
                rank
                value
              }
              htFor {
                rank
                value
              }
              passAccuracy {
                rank
                value
              }
              offsides {
                rank
                value
              }
              passesAccurate {
                rank
                value
              }
              redCards {
                rank
                value
              }
              shotsInsideBox {
                rank
                value
              }
              shotsOnGoal {
                value
                rank
              }
              shotsOffGoal {
                rank
                value
              }
              shotsOutsideBox {
                rank
                value
              }
              totalMatches {
                rank
                value
              }
              totalPasses {
                rank
                value
              }
              totalShots {
                rank
                value
              }
              yellowCards {
                rank
                value
              }
              xg {
                rank
                value
              }
            }
          }
        }
      }
    }
  `);

  return allTeamsJson.nodes;
};
