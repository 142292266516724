import React from "react";
import { graphql } from "gatsby";

import { SeoComponent } from "../../components/SeoComponent";
import UpcomingMatchesStatistics from "../../components/UpcomingMatchStatistics";
import FinishedMatchStatistics from "../../components/FinishedMatchStatistics";

function Match(props) {
  const data = props.data.matchesJson;
  const date = data.newDate === null ? data.date : data.newDate;

  return (
    <>
      {new Date() < new Date(date) ? (
        <UpcomingMatchesStatistics matchesJson={props.data.matchesJson} />
      ) : (
        <FinishedMatchStatistics matchesJson={props.data.matchesJson} />
      )}
    </>
  );
}

export default Match;

export const query = graphql`
  query ($id: String) {
    matchesJson(id: { eq: $id }) {
      id
      status
      note
      away {
        fullName
        shortName
        slug
      }
      home {
        fullName
        shortName
        slug
      }
      fields {
        slug
      }
      statistics {
        away {
          xg
          ballPossession
          blockedShots
          cornerKicks
          fouls
          goalkeeperSaves
          offsides
          passesAccurate
          passAccuracy
          redCards
          shotsInsideBox
          shotsOffGoal
          shotsOnGoal
          shotsOutsideBox
          totalPasses
          totalShots
          yellowCards
        }
        home {
          xg
          ballPossession
          blockedShots
          cornerKicks
          fouls
          goalkeeperSaves
          offsides
          passAccuracy
          passesAccurate
          redCards
          shotsInsideBox
          shotsOnGoal
          shotsOffGoal
          shotsOutsideBox
          totalPasses
          totalShots
          yellowCards
        }
      }
      city
      date
      newDate
      referee
      round
      note
      venueName
      htScore {
        away
        home
      }
      ftScore {
        away
        home
      }
    }
  }
`;

export const Head = ({ data }) => {
  const { home, away, round } = data.matchesJson;
  return (
    <SeoComponent
      title={`${home.fullName} - ${away.fullName}`}
      description={`Türkiye Futbol Süper Ligi 2024-25 Sezonu ${round}. hafta ${home.fullName} - ${away.fullName} maçı`}
      keywords={`${round}. hafta, ${home.fullName}, ${away.fullName}, ${home.fullName}-${away.fullName}`}
    />
  );
};
