export const matchStatisticsNameMap = {
  xg: { label: "Gol Beklentisi" },
  ballPossession: { label: "Topa Sahip Olma", textToAppend: "%" },
  blockedShots: { label: "Engellenen Şutlar" },
  cornerKicks: { label: "Kornerler" },
  fouls: { label: "Fauller" },
  goalkeeperSaves: { label: "Kaleci Kurtarışları" },
  offsides: { label: "Ofsaytlar" },
  passAccuracy: { label: "Başarılı Pas Yüzdesi", textToAppend: "%" },
  passesAccurate: { label: "Başarılı Pas" },
  redCards: { label: "Kırmızı Kartlar" },
  shotsInsideBox: { label: "Ceza Sahası İçinden Şutlar" },
  shotsOffGoal: { label: "Kaleyi Bulmayan Şutlar" },
  shotsOnGoal: { label: "Kaleyi Bulan Şutlar" },
  shotsOutsideBox: { label: "Ceza Sahası Dışından Şutlar" },
  totalPasses: { label: "Toplam Pas" },
  totalShots: { label: "Toplam Şut" },
  yellowCards: { label: "Sarı Kartlar" },
  htFor: { label: "İlk Yarı Attığı Gol" },
  htAgainst: { label: "İlk Yarı Yediği Gol" },
  ftFor: { label: "Toplam Attığı Gol" },
  ftAgainst: { label: "Toplam Yediği Gol" },
  totalMatches: { label: "Toplam Maç Sayısı" },
};

export const teamStatisticsNameMap = {
  avgXg: { label: "Maç Başına Gol Beklentisi" },
  avgBallPossession: { label: "Maç Başına Topa Sahip Olma", textToAppend: "%" },
  avgBlockedShots: { label: "Maç Başına Engellenen Şut" },
  avgCornerKicks: { label: "Maç Başına Korner" },
  avgFouls: { label: "Maç Başına  Faul" },
  avgGoalkeeperSaves: { label: "Maç Başına Kaleci Kurtarışı" },
  avgOffsides: { label: "Maç Başına Ofsayt" },
  avgPassAccuracy: {
    label: "Maç Başına Başarılı Pas Yüzdesi",
    textToAppend: "%",
  },
  avgPassesAccurate: { label: "Maç Başına Başarılı Pas" },
  avgRedCards: { label: "Toplam Kırmızı Kartlar" },
  avgShotsInsideBox: { label: "Maç Başına Ceza Sahası İçinden Şut" },
  avgShotsOffGoal: { label: "Maç Başına Kaleyi Bulmayan Şut" },
  avgShotsOnGoal: { label: "Maç Başına Kaleyi Bulan Şut" },
  avgShotsOutsideBox: { label: "Maç Başına Ceza Sahası Dışından Şut" },
  avgTotalPasses: { label: "Maç Başına Pas" },
  avgTotalShots: { label: "Maç Başına Şut" },
  avgYellowCards: { label: "Maç Başına Sarı Kart" },
  avgHtFor: { label: "Maç Başına İlk Yarı Attığı Gol" },
  avgHtAgainst: { label: "Maç Başına İlk Yarı Yediği Gol" },
  avgFtFor: { label: "Maç Başına Attığı Gol" },
  avgFtAgainst: { label: "Maç Başına Yediği Gol" },
  avgTotalMatches: { label: "Ortalama Toplam Maç Sayısı" },
  yellowCards: { label: "Toplam Sarı Kart" },
  redCards: { label: "Toplam Kırmızı Kart" },
  xgPerGoal: { label: "Gol Başına XG" },
  xgPerShot: { label: "Şut Başına XG" },
  goalPerShotsOnTarget: { label: "Kaleyi Bulan Şut Başına Gol" },
  savePercentage: { label: "Kurtarış Yüzdesi", textToAppend: "%" },
  blockedShotsRatio: { label: "Engellenen Şut Yüzdesi", textToAppend: "%" },
  onTargetShotsRatio: { label: "Kaleyi Bulan Şut Yüzdesi", textToAppend: "%"  },
  goalsPerShot: { label: "Şut Başına Gol" },
};

export const statisticsCardNameMap = {
  avgXg: { label: "Gol Beklentisi" },
  avgBallPossession: { label: "Topa Sahip Olma", textToAppend: "%" },
  blockedShots: { label: "Engellenen Şut" },
  cornerKicks: { label: "Korner" },
  fouls: { label: "Faul" },
  goalkeeperSaves: { label: "Kaleci Kurtarışı" },
  offsides: { label: "Ofsayt" },
  avgPassAccuracy: { label: "Başarılı Pas Yüzdesi", textToAppend: "%" },
  passesAccurate: { label: "Başarılı Pas" },
  redCards: { label: "Kırmızı Kart" },
  shotsInsideBox: { label: "Ceza Sahası İçinden Şut" },
  shotsOffGoal: { label: "Kaleyi Bulmayan Şut" },
  shotsOnGoal: { label: "Kaleyi Bulan Şut" },
  shotsOutsideBox: { label: "Ceza Sahası Dışından Şut" },
  totalPasses: { label: "Toplam Pas" },
  totalShots: { label: "Toplam Şut" },
  yellowCards: { label: "Sarı Kart" },
  htFor: { label: "İlk Yarı Atılan Gol" },
  htAgainst: { label: "İlk Yarı Yenilen Gol" },
  ftFor: { label: "Atılan Gol" },
  ftAgainst: { label: "Yenilen Gol" },
  totalMatches: { label: "Toplam Maç Sayısı" },
  avarageYellowCards: { label: "Ortalama Sarı Kart" },
  xgPerGoal: { label: "Gol Başına XG" },
  xgPerShot: { label: "Şut Başına XG" },
  goalPerShotsOnTarget: { label: "Kaleyi Bulan Şut Başına Gol" },
  savePercentage: { label: "Kurtarış Yüzdesi", textToAppend: "%" },
  blockedShotsRatio: { label: "Engellenen Şut Yüzdesi", textToAppend: "%" },
  onTargetShotsRatio: { label: "Kaleyi Bulan Şut Yüzdesi", textToAppend: "%"  },
  goalsPerShot: { label: "Şut Başına Gol" },
};
